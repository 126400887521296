import React from 'react'

import SEO from 'components/seo'
import Header from 'components/Header';
import Layout from 'components/layout'
import Link from 'components/Link';
import i18nx from 'components/locale/i18nx';

const NotFoundPage = ({ location, ...props }) => {
  const lang = location && location.pathname.split('/')[1];

  return (
    <Layout lang={lang}>
      <SEO title="404: Not found" />
      <Header lang={lang}>
        <div class="ss_breadcrumb text-center">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h1>404 Page Not Found</h1>
                <ul>
                  <li><Link to="/">{i18nx('home')}</Link><span> / 404 Error</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <section className="spacer_top spacer_bottom ss_error_bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="ss_error">
                <div className="ss_error_svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182 104">
                    <path d="M34,28s29.427-7.172,43-13S102.242,4.046,111,2s11.467-1.4,15,1,8.561,7.118,5,13-5.93,9-3,11a13.025,13.025,0,0,0,13,1c4.872-2.487,16.457-7.519,24-2s5.114,13.023,2,18-11.156,6.224-13,10-1.093,8.777,3,9,12.308-6.044,20,1,3.579,21.655-3,27-15.344,10.465-31,10-42.351-6.971-54-7-37.566,7.974-51,9S10.368,99.6,4,94,0.309,81.984,7,78s11.679-7.028,11-12S4.922,60.57,4,52,5.094,37.218,34,28Z" />
                  </svg>
                </div>
                <div className="ss_error_one">
                  <h1><span>4</span><span>0</span><span>4</span></h1>
                  <img className="img-fluid" src="/assets/images/ss-404.png" alt={404} title={404} />
                </div>
                <h1>OOPS!</h1>
                <p>Page Not Found</p>
                <Link to="/" className="ss_btn">go back to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
